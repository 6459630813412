var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.summeNurGepruefte))]),_vm._v(" von "),_c('b',[_vm._v(_vm._s(_vm.summeAlle))]),_vm._v(" Hydrant/en wurden geprüft: ")]),_c('table',{attrs:{"cellspacing":"14"}},[_c('tr',[_c('td',[_vm._v("00")]),_c('td',[_vm._v("Hydrant ohne Mangel")]),_c('td',[_vm._v(" "+_vm._s(_vm.summeNurGepruefte - _vm.summeAlleMitMindestensEinemMangel)+" ")])]),_vm._l((_vm.data.maengel),function(mangel){return _c('tr',[_c('td',[_vm._v(_vm._s(mangel.schluessel))]),_c('td',[_vm._v(_vm._s(mangel.name))]),_c('td',[_vm._v(" "+_vm._s(_vm.data.hydranten.filter(function (o) { if ( o.status === 1 && o.mangel.filter(function (o2) { return [mangel.schluessel].includes(o2); }).length > 0 && ( !_vm.setNurNiedrigsteMangelnummerZaehlt || o.mangel.filter(function (o2) { return o2 < mangel.schluessel; }).length <= 0 ) ) { return true; } return false; }).length)+" ")])])})],2),_c('v-switch',{attrs:{"label":"nur die niedrigste Schlüsselnummer des Hydrantenbefunds angeben"},model:{value:(_vm.setNurNiedrigsteMangelnummerZaehlt),callback:function ($$v) {_vm.setNurNiedrigsteMangelnummerZaehlt=$$v},expression:"setNurNiedrigsteMangelnummerZaehlt"}}),_c('br'),_c('br'),_c('p',[_vm._v(" Hydranten mit Befunden (z.B. 03, 06, 09, 10 und 12) die dringenden Handlungsbedarf aufweisen: ")]),_c('table',{attrs:{"cellspacing":"14"}},[_vm._m(0),_vm._l((_vm.data.hydranten.filter(function (o) {
      var res = false;
      o.mangel.forEach(function (m) {
        if (
          ['03', '06', '09', '10', '12'].includes(m)
          && (
            !_vm.setNurNiedrigsteMangelnummerZaehlt
            || o.mangel.filter(function (o2) { return o2 < m; }).length <= 0
          )
        ) {
          res = true;
        }
      });
      return res;
    })),function(hyd){return _c('tr',{attrs:{"valign":"top"}},[_c('td',[_vm._v(_vm._s(_vm.data.strassen.filter(function (o) { return o.id === hyd.idStrasse; })[0].strasse+' '+hyd.hausnummer))]),_c('td',[_vm._v(_vm._s(hyd.lage.trim() || '-'))]),_c('td',_vm._l((hyd.mangel),function(m){return _c('div',[_vm._v("["+_vm._s(_vm.data.maengel.filter(function (o) { return o.schluessel === m; })[0].schluessel)+"] "+_vm._s(_vm.data.maengel.filter(function (o) { return o.schluessel === m; })[0].name))])}),0)])})],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticStyle:{"font-weight":"bold"}},[_c('td',[_vm._v("Straßenname Hs.Nr.")]),_c('td',[_vm._v("Lage Hydrant")]),_c('td',[_vm._v("Befund / Hinweis")])])}]

export { render, staticRenderFns }