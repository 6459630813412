<template>
    <div class="pa-3">
      <p>
        <b>{{ summeNurGepruefte }}</b> von <b>{{ summeAlle }}</b> Hydrant/en wurden geprüft:
        <!--<br/>
        <b>{{ summeAlleMitMindestensEinemMangel }}</b> von <b>{{ summeAlle }}</b> Hydrant/en haben Mängel:-->
      </p>
      <table cellspacing="14" style="">
        <tr>
          <td>00</td>
          <td>Hydrant ohne Mangel</td>
          <td>
            {{ summeNurGepruefte - summeAlleMitMindestensEinemMangel }}
          </td>
        </tr>
        <tr v-for="mangel in data.maengel">
          <td>{{ mangel.schluessel }}</td>
          <td>{{ mangel.name }}</td>
          <td>
            {{ data.hydranten.filter((o) => {
              if (
                o.status === 1
                && o.mangel.filter((o2) => [mangel.schluessel].includes(o2)).length > 0
                && (
                  !setNurNiedrigsteMangelnummerZaehlt
                  || o.mangel.filter((o2) => o2 < mangel.schluessel).length <= 0
                )
              ) {
                return true;
              }
              return false;
            }).length }}
          </td>
        </tr>
      </table>
      <v-switch v-model="setNurNiedrigsteMangelnummerZaehlt" label="nur die niedrigste Schlüsselnummer des Hydrantenbefunds angeben"/>
      <br/><br/>
      <p>
        Hydranten mit Befunden (z.B. 03, 06, 09, 10 und 12) die dringenden Handlungsbedarf aufweisen:
      </p>
      <table cellspacing="14">
        <tr style="font-weight:bold;">
          <td>Straßenname Hs.Nr.</td>
          <td>Lage Hydrant</td>
          <td>Befund / Hinweis</td>
        </tr>
        <tr
          valign="top"
          v-for="hyd in data.hydranten.filter((o) => {
          let res = false;
          o.mangel.forEach((m) => {
            if (
              ['03', '06', '09', '10', '12'].includes(m)
              && (
                !setNurNiedrigsteMangelnummerZaehlt
                || o.mangel.filter((o2) => o2 < m).length <= 0
              )
            ) {
              res = true;
            }
          });
          return res;
        })">
          <td>{{ data.strassen.filter((o) => o.id === hyd.idStrasse)[0].strasse+' '+hyd.hausnummer }}</td>
          <td>{{ hyd.lage.trim() || '-' }}</td>
          <td>
            <div v-for="m in hyd.mangel">[{{ data.maengel.filter((o) => o.schluessel === m)[0].schluessel }}] {{ data.maengel.filter((o) => o.schluessel === m)[0].name }}</div>
          </td>
        </tr>
      </table>
    </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    setNurNiedrigsteMangelnummerZaehlt: true,
  }),
  computed: {
    summeAlle() {
      return this.data.hydranten.length;
    },
    summeNurGepruefte() {
      return this.data.hydranten.filter((o) => o.status === 1).length;
    },
    summeAlleMitMindestensEinemMangel() {
      return this.data.hydranten.filter((o) => {
        if (
          o.status === 1
          && o.mangel.filter((o2) => o2 !== '').length > 0
        ) {
          return true;
        }
        return false;
      }).length
    }
  },
  watch: {
  },
  methods: {
  },
  created() {
    console.log(this.data);
  },
};
</script>
